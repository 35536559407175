import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function AgentMatchDash() {
    const [eventType, seteventType] = useState('1');
    const [MatchStatus, setMatchStatus] = useState({});
    const [Selectinid, setSelectinid] = useState({})

    const url = window.location.href;
    const para = url.split('/');
    const eventid = para[4];

    useEffect(() => {

        var ssid = cookies.get('sid');
        if (!ssid) return;
        if (!eventid) return;

        axios.post('https://flagapi.in/agentapi/getEventType', {
            sid: ssid,
            eventId: eventid
        })
            .then(result => {
                console.log(result)
                if (result.status === 200) {

                    seteventType(result.data[0].eventType);
                    setMatchStatus(result.data[0].matchStatus);
                    setSelectinid(result.data[0].marketId);
                }
            }

            ).catch(e => {
                //setIsError(true);
            });
    }, [])

    return (
        <React.Fragment>

            <div class="row wrapper border-bottom white-bg page-heading">
                <div class="col-lg-10">
                    <h2>Matches</h2>
                    <ol class="breadcrumb">
                        <li>
                            <a href="/">Dashboard</a>
                        </li>
                        <li>
                            <a href="/liveMatches">Matches</a>
                        </li>

                    </ol>
                </div>
                <div class="col-lg-2">
                </div>
            </div>
            <div class="wrapper wrapper-content animated fadeInRight">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                            <div class="ibox-title">
                                <h1>Agent Match Dashboard</h1>
                            </div>
                            <div class="ibox-content table-responsive">
                                <p style={{ textAlign: "center" }}>
                                    <Link to={`/betSlip/${eventid}/${Selectinid}`}><button type="button" class="btn btn-primary btn-lg">Bet Slips</button></Link>
                                    {/* <Link to="/betslip2"><button type="button" class="btn btn-primary btn-lg">Bet Slips2</button></Link> */}
                                    {eventType == 4 &&
                                        <Link to={`/sessionBetSlip/${eventid}/${Selectinid}`}><button type="button" class="btn btn-primary btn-lg">Session Bet Slip</button></Link>
                                    }
                                    <Link to={`/liveReport/${eventid}/${eventType}`}><button type="button" class="btn btn-primary btn-lg">Live Report</button></Link>

                                    <Link to="/companyReport"><button type="button" class="btn btn-primary btn-lg">Company Report</button></Link>

                                    <Link to={`/clientReport/${eventid}`}><button type="button" class="btn btn-primary btn-lg">Client Report</button></Link>

                                    <Link to={`/collectionReport/${eventid}`} ><button type="button" class="btn btn-primary btn-lg">Collection Report</button></Link>
                                    {eventType == 4 &&
                                        <Link to="/sessionEarnrepo"><button type="button" class="btn btn-primary btn-lg">Session Earning Report</button></Link>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </React.Fragment>
    )
}
