import React, { useEffect, useState, useRef } from 'react'
import Dashboared from './Dashboared';
import Footer from './Footer';
import Header from './Header';
import Master from './Master';
import SuperStocklist from './SuperStocklist';
import SidebarMenu from './SidebarMenu';
import Stocklist from './StockList';
import Agent from './Agent';
import Matches from './Matches';
import Myclient from './Myclient';
import CommiNLimit from './CommiNLimit';
import CreateSC from './CreateSC';
import Createsslist from './Createsslist';
import CreateStockist from './CreateStockist';
import CreateAgent from './CreateAgent';
import AgentDash from './AgentDash';
import CoinHistory from './CoinHistory';
import DirectAgent from './DirectAgent';
import MatchLedger from './MatchLedger'
import Paycash from './Paycash'
import ReceiveCash from './ReceiveCash'
import CashLedger from './CashLedger'
import Ledger from './Ledger'
import Changepassagent from './Changepassagent';
import ManagePass from './ManagePass';
import Language from './Language';
import AgentMatchDash from './AgentMatchDash';
import LiveReport from './LiveReport';
import BetSlip from './BetSlip';
import Betslip2 from './Betslip2';
import Sessionbetslip from './Sessionbetslip';
import CompanyReport from './CompanyReport';
import ClientReport from './ClientReport';
import CollectionReoprt from './CollectionReoprt';
import SessionEarnRepo from './SessionEarnRepo';
import CollectionReport from './CollectionReport';
import MyLedger from './MyLedger';
import ProfitLoss from './ProfitLoss';
import SubComReport from './SubComReport';
import ChangeStatus from './ChangeStatus';
import Login from './Login';
import DeskLogin from './DeskLogin';
import Marquee from './Marquee';
import Client from './Client';
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import ManageMatches from './ManageMatches';
import Sportslist from './Sportslist';
import ClientDash from './ClientDash';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import SubCompany from './SubCompany';
import CricketList from './CricketList';
import SoccerList from './SoccerList';
import TennisList from './TennisList';
import CasinocollRepo from './CasinocollRepo';
import CreateClient from './CreateClient';
import PasswModel from './PasswModel'
import ChangeClientPass from './ChangeClientPass';
import LiveMatches from './LiveMatches';
import LiveMatchReports from './LiveMatchReports';
import EditAgent from './EditAgent';
import EditClient from './EditClient';
import CreateCompany from './CreateCompany';

toast.configure()

const cookies = new Cookies();

export default function Combine(props) {

    var eventT = 1;

    if (props.match.params.eventType === 'dashboard') {
        eventT = 1;
    }
    else if (props.match.params.eventType === 'master') {
        eventT = 2;
    }
    else if (props.match.params.eventType === 'superStockList') {
        eventT = 3;
    }
    else if (props.match.params.eventType === 'stockList') {
        eventT = 4;
    }
    else if (props.match.params.eventType === 'Agent') {
        eventT = 5;
    }
    else if (props.match.params.eventType === 'editClient') {
        eventT = 6;
    }
    else if (props.match.params.eventType === 'Clients') {
        eventT = 7;
    }
    else if (props.match.params.eventType === 'commnlimit') {
        eventT = 8;
    }
    else if (props.match.params.eventType === 'createSubCompany') {
        eventT = 9
    }
    else if (props.match.params.eventType === 'createsslist') {
        eventT = 10
    }
    else if (props.match.params.eventType === 'createstocklist') {
        eventT = 11
    }
    else if (props.match.params.eventType === 'createAgent') {
        eventT = 12
    }
    else if (props.match.params.eventType === 'AgentDash') {
        eventT = 13
    }
    else if (props.match.params.eventType === 'receiveCash') {
        eventT = 14
    }
    else if (props.match.params.eventType === 'payCash') {
        eventT = 15
    }
    else if (props.match.params.eventType === 'ledger') {
        eventT = 16
    }
    else if (props.match.params.eventType === 'CashLedger') {
        eventT = 17
    }
    else if (props.match.params.eventType === 'MatchLedger') {
        eventT = 18
    }
    else if (props.match.params.eventType === 'editAgent') {
        eventT = 19
    }
    else if (props.match.params.eventType === 'companyReport') {
        eventT = 20
    }
    else if (props.match.params.eventType === 'CoinHistory') {
        eventT = 21
    }
    else if (props.match.params.eventType === 'ChangepassAgent') {
        eventT = 22
    }
    else if (props.match.params.eventType === 'Managepass') {
        eventT = 23
    }
    else if (props.match.params.eventType === 'Changeclientpass') {
        eventT = 24
    }
    else if (props.match.params.eventType === 'AgnetMatchDash') {
        eventT = 25
    }
    else if (props.match.params.eventType === 'liveReport') {
        eventT = 26
    }
    else if (props.match.params.eventType === 'betSlip') {
        eventT = 27
    }
    else if (props.match.params.eventType === 'liveMatchreport') {
        eventT = 28
    }
    else if (props.match.params.eventType === 'sessionBetSlip') {
        eventT = 29
    }
    else if (props.match.params.eventType === 'liveMatches') {
        eventT = 30
    }
    else if (props.match.params.eventType === 'clientReport') {
        eventT = 31
    }
    else if (props.match.params.eventType === 'collectionReport') {
        eventT = 32
    }
    else if (props.match.params.eventType === 'sessionEarnrepo') {
        eventT = 33
    }
    else if (props.match.params.eventType === 'CollectionReoprt') {
        eventT = 34
    }
    else if (props.match.params.eventType === 'Myledger') {
        eventT = 35
    }
    else if (props.match.params.eventType === 'profitloss') {
        eventT = 36
    }
    else if (props.match.params.eventType === 'subcomreport') {
        eventT = 37
    }
    else if (props.match.params.eventType === 'sportsList') {
        eventT = 38
    }

    else if (props.match.params.eventType === 'cricketList') {
        eventT = 39
    }
    else if (props.match.params.eventType === 'ClientDash') {
        eventT = 40
    }
    else if (props.match.params.eventType === 'soccerList') {
        eventT = 41
    }
    else if (props.match.params.eventType === 'tennisList') {
        eventT = 42
    }
    else if (props.match.params.eventType === 'casinoCollrepo') {
        eventT = 43
    }
    else if (props.match.params.eventType === 'newuser') {
        eventT = 44
    }
    else if (props.match.params.eventType === 'createclient') {
        eventT = 45
    }

    else if (props.match.params.eventType === 'createcompany') {
        eventT = 46
    }


    const [isLoggedIn, setLoggedIn] = useState(null);
    const [tabMenu, setTabMenu] = useState(eventT);
    const [user, setuser] = useState('');
    const [balance, setbalance] = useState(0);
    const [ldb, setldb] = useState(0);
    const [lwc, setlwc] = useState(0);
    const [le, setle] = useState(0);

    const [level, setlevel] = useState('');
    const [matchcomm, setmatchcomm] = useState('');
    const [sessioncomm, setsessioncomm] = useState('');
    const [Changestatus, setChangestatus] = useState(false);
    const [updatedStatus, setupdatedStatus] = useState(true);
    const [agentId, setagentId] = useState('');
    const [agentType, setagentType] = useState('');
    const [lev, setlev] = useState('');
    const [currentStatus, setcurrentStatus] = useState('');
    const [rollingcomm, setrollingcomm] = useState('0')
    const [ShowPassModel, setShowPassModel] = useState(false);
    const [companyContact, setcompanyContact] = useState('');
    const [updateBalance, setupdateBalance] = useState(false);
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const [userLevel, setuserLevel] = useState(null);
    const [open, setopen] = useState(false);
    const [agentStack, setagentStack] = useState([]);
    const [searchBtn, setsearchBtn] = useState(true);
    const [editCompany, seteditCompany] = useState(false);
    const [share, setshare] = useState('')

    const checkUpdateBalance = () => {
        setupdateBalance(!updateBalance);
    }
    const changeUpdatedStatus = () => {
        setupdatedStatus(!updatedStatus);
    }
    const HandlePopup = (val1, val2, obj) => {
        if (val1 === 1) {
            setChangestatus(val2);
            if (val2 === true) {
                setlev(obj.lev);
                setagentId(obj.user);
                setcurrentStatus(obj.userstatus);
                setagentType(obj.type);
            }
        }
        if (val1 === 3) {
            seteditCompany(val2);
            if (val2 === true) {
                setagentId(obj.user);

            }
        }
    }
    const dsClick = () => {
        setIsActive(!isActive);
    }
    const handleOpen2 = (OpenTru) => {
        setopen(OpenTru)
    }

    useEffect(() => {
        setTabMenu(eventT);

    }, [eventT]);


    useEffect(() => {

        if (cookies.get('sid')) {
            setLoggedIn(true);

            var ssid = cookies.get('sid');
            axios.post('https://flagapi.in/agentapi/agentInfo', {
                sid: ssid
            })
                .then(result => {

                    if (result.status === 200) {

                        setuser(result.data.id);
                        setshare(result.data.share);
                        setuserLevel(result.data.level);
                        setbalance(result.data.balance);
                        setldb(result.data.ldb);
                        setlwc(result.data.lwc);
                        setle(result.data.le);

                        setmatchcomm(result.data.matchcomm);
                        setsessioncomm(result.data.sessioncomm);
                        setrollingcomm(result.data.rollingcomm);

                        let arr = [];
                        let obj = { userid: result.data.id, level: result.data.level }
                        arr.push(obj);

                        setagentStack(arr);


                        if (result.data.level == 1) {
                            setlevel('Admin');
                            setcompanyContact('admin');
                        }
                        else if (result.data.level == 2) {
                            setlevel('Sub Admin');
                            setcompanyContact('Admin');
                        }
                        else if (result.data.level == 3) {
                            setlevel('Super Super');
                            setcompanyContact('Sub Admin');
                        }
                        else if (result.data.level == 4) {
                            setlevel('Super');
                            setcompanyContact('Super Super');
                        }
                        else if (result.data.level == 5) {
                            setlevel('Agent');
                            setcompanyContact('Super');
                        }
                    }
                    else {
                        setLoggedIn(false);
                        cookies.remove('sid', { path: '/' });

                    }
                }

                ).catch(e => {
                    //setIsError(true);
                });
        }
        else {
            setLoggedIn(false);
        }
    }, [isLoggedIn, updateBalance, user]);

    const checkShowLogin = (bool) => {
        setLoggedIn(bool);
    }

    const searchBtnClicked = () => {
        setsearchBtn(!searchBtn);
    }


    const pushAgent = (agent, cla) => {

        let arr = [...agentStack];

        arr.push({ userid: agent, level: cla });

        setagentStack(arr);

    }

    const popAgent = (index) => {
        let arrr = [...agentStack];

        setagentStack(arrr.splice(0, index + 1));
    }


    return (
        <React.Fragment>

            {
                isLoggedIn && <body className={`${isActive > 0 ? "pace-done body-small mini-navbar " : "pace-done body-small"}`} >
                    <div id="wrapper">
                        {Changestatus && <ChangeStatus changeUpdatedStatus={changeUpdatedStatus} HandlePopup={HandlePopup} lev={lev} agentId={agentId} currentStatus={currentStatus} agentType={agentType} />}
                        {<nav class="navbar-default navbar-static-side" role="navigation" style={{ height: '100%' }} ref={dropdownRef}>
                            <SidebarMenu level={level} userLevel={userLevel} user={user} />
                        </nav>
                        }
                        <div id="page-wrapper" class="gray-bg dashbard-1" style={{ minHeight: '764px' }}>
                            <Header dsClick={dsClick} isActive={isActive} />
                            {/* <MarqueePopup/> */}
                            <Marquee />
                            {tabMenu === 1 &&
                                <Dashboared share={share} companyContact={companyContact} level={level} user={user} balance={balance} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} />}
                            {tabMenu === 2 &&
                                <Master share={share} agentType={agentType} currentStatus={currentStatus} agentId={agentId} lev={lev} HandlePopup={HandlePopup} changeUpdatedStatus={changeUpdatedStatus} Changestatus={Changestatus} updatedStatus={updatedStatus} handleOpen2={handleOpen2} searchBtn={searchBtn} agentStack={agentStack} />}
                            {tabMenu === 3 &&
                                <SuperStocklist share={share} agentType={agentType} currentStatus={currentStatus} agentId={agentId} lev={lev} HandlePopup={HandlePopup} changeUpdatedStatus={changeUpdatedStatus} Changestatus={Changestatus} updatedStatus={updatedStatus} handleOpen2={handleOpen2} searchBtn={searchBtn} agentStack={agentStack} />}
                            {tabMenu === 4 &&
                                <Stocklist share={share} agentType={agentType} currentStatus={currentStatus} agentId={agentId} lev={lev} HandlePopup={HandlePopup} changeUpdatedStatus={changeUpdatedStatus} Changestatus={Changestatus} updatedStatus={updatedStatus} handleOpen2={handleOpen2} searchBtn={searchBtn} agentStack={agentStack} />}
                            {tabMenu === 5 &&
                                <Agent share={share} agentType={agentType} currentStatus={currentStatus} agentId={agentId} lev={lev} HandlePopup={HandlePopup} changeUpdatedStatus={changeUpdatedStatus} Changestatus={Changestatus} updatedStatus={updatedStatus} handleOpen2={handleOpen2} searchBtn={searchBtn} agentStack={agentStack} />}
                            {tabMenu === 44 &&
                                <Client agentType={agentType} currentStatus={currentStatus} agentId={agentId} lev={lev} HandlePopup={HandlePopup} changeUpdatedStatus={changeUpdatedStatus} Changestatus={Changestatus} updatedStatus={updatedStatus} handleOpen2={handleOpen2} searchBtn={searchBtn} agentStack={agentStack} />}
                            {tabMenu == 6 &&
                                <EditClient user={user} />}
                            {tabMenu == 7 &&
                                <Myclient handleOpen2={handleOpen2} />}
                            {tabMenu == 8 &&
                                <CommiNLimit checkUpdateBalance={checkUpdateBalance} agentStack={agentStack} user={user} balance={balance} ldb={ldb} lwc={lwc} le={le} userLevel={userLevel} />}
                            {tabMenu == 9 &&
                                <CreateSC share={share} balance={balance} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} />}


                            {tabMenu == 45 &&
                                <CreateClient />}

                            {tabMenu == 46 &&
                                <CreateCompany share={share} balance={balance} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} level={level} />
                            }
                            {tabMenu == 10 &&
                                <Createsslist share={share} balance={balance} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} level={level} />}
                            {tabMenu == 11 &&
                                <CreateStockist share={share} balance={balance} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} />}
                            {tabMenu == 12 &&
                                <CreateAgent share={share} balance={balance} ldb={ldb} lwc={lwc} le={le} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} />}
                            {tabMenu == 13 &&
                                <AgentDash userLevel={userLevel} />}
                            {tabMenu == 14 &&
                                <ReceiveCash agentId={agentId} />}
                            {tabMenu == 15 &&
                                <Paycash />}
                            {tabMenu == 16 &&
                                <Ledger />}
                            {tabMenu == 17 &&
                                <CashLedger />}
                            {tabMenu == 18 &&
                                <MatchLedger />}
                            {tabMenu == 19 &&
                                <EditAgent share={share} balance={balance} matchcomm={matchcomm} sessioncomm={sessioncomm} rollingcomm={rollingcomm} />}
                            {tabMenu == 20 &&
                                <CompanyReport />}
                            {tabMenu == 21 &&
                                <CoinHistory />}
                            {tabMenu == 22 &&
                                <Changepassagent user={user} agentStack={agentStack} />}
                            {tabMenu == 23 &&
                                <ManagePass />}
                            {tabMenu == 24 &&
                                <ChangeClientPass user={user} agentStack={agentStack} />}
                            {tabMenu == 25 &&
                                <AgentMatchDash />}
                            {tabMenu == 26 &&
                                <LiveReport />}
                            {tabMenu == 27 &&
                                <BetSlip />}
                            {tabMenu == 28 &&
                                <LiveMatchReports />}
                            {tabMenu == 29 &&
                                <Sessionbetslip />}
                            {tabMenu == 30 &&
                                <LiveMatches user={user} />}
                            {tabMenu == 31 &&
                                <ClientReport user={user} />}
                            {tabMenu == 32 &&
                                <CollectionReoprt user={user} level={userLevel} />}
                            {tabMenu == 33 &&
                                <SessionEarnRepo />}
                            {tabMenu == 34 &&
                                <CollectionReport user={user} level={userLevel} />}
                            {tabMenu == 35 &&
                                <MyLedger />}
                            {tabMenu == 36 &&
                                <ProfitLoss user={user} />}
                            {tabMenu == 37 &&
                                <SubComReport />}
                            {tabMenu == 38 &&
                                <Sportslist />}

                            {tabMenu == 39 &&
                                <CricketList />}
                            {tabMenu == 40 &&
                                <ClientDash />}
                            {tabMenu == 41 &&
                                <SoccerList />}
                            {tabMenu == 42 &&
                                <TennisList />}
                            {/* {tabMenu == 43 &&
    <CasinocollRepo user={user}/>
        } */}


                            {/* <Footer/> */}
                        </div>
                    </div>
                </body>}
            {/* <Login/> */}
            {isLoggedIn === false && <DeskLogin checkShowLogin={checkShowLogin} />}
        </React.Fragment>
    )
}
