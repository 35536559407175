import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";


const cookies = new Cookies();


export default function CollectionReport(props) {
   const [PayReport, setPayReport] = useState([]);
   const [ReceiveReport, setReceiveReport] = useState([]);
   const [ClearReport, setClearReport] = useState([]);
   const [refresh, setrefresh] = useState(true);


   var sum_A1 = 0, sum_B1 = 0, sum_C1 = 0

   useEffect(() => {
      var ssid = cookies.get('sid');
      if (!ssid) return;
      if (props.user == '' || !props.level) return;


      axios.post('https://flagapi.in/agentapi/CollectionReport', {
         sid: ssid,
         id: props.user,
         level: props.level

      })
         .then(result => {
            var res = [];
            var positiveArr = [];
            var negativeArr = [];
            var equal = [];

            if (result.status === 200) {

               if (props.level == '5') {

                  for (let i = 0; i < result.data.length; i++) {
                     result.data[i].ledgerExpo = result.data[i].LedgerBal * -1;
                     result.data[i].creditLimit = result.data[i].LimitCurr;

                  }

               }

               for (let key in result.data) {

                  if (result.data.hasOwnProperty(key)) {
                     res.push(result.data[key]);

                     // res.forEach(function(item){
                     if (result.data[key].ledgerExpo < 0) {
                        negativeArr.push(result.data[key]);

                     }
                     else if (result.data[key].ledgerExpo > 0) {
                        positiveArr.push(result.data[key]);

                     }
                     else if (result.data[key].ledgerExpo == 0) {
                        equal.push(result.data[key])
                     }
                     // })
                     console.log(result.data);
                  }

               }

               setPayReport(positiveArr);
               setReceiveReport(negativeArr);
               setClearReport(equal);

               if (refresh) {
                  // setrefresh(false);
                  setTimeout(() => { setrefresh(false); }, 1000);
               }



            }
         }
         ).catch(e => {
            //setIsError(true);
         });
   }, [props.user, props.level])

   return (
      <React.Fragment>
         <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-lg-10">
               <h2>COLLECTION REPORT</h2>
               <ol class="breadcrumb">
                  <li>
                     <a href="/agent/dashboard">Dashboard</a>
                  </li>
                  <li>
                     <a href="#">Admin</a>
                  </li>
                  <li>
                     <a><strong>Collection Report</strong></a>
                  </li>
               </ol>
            </div>
            <div class="col-lg-2">
            </div>
         </div>

         <div class="wrapper wrapper-content animated fadeInRight">

            {/* 
            <div class="row">
              <div class="col-lg-12">
                  <div class="ibox float-e-margins" style={{marginBottom:'10px'}}>
                      <div class="ibox-title" style={{padding:'10px 0px 0px'}}>
                         
                          <ul class="input-list" style={{padding:'0px'}}>
    
            <li></li>
          
            <li class="input-list" style={{display: "inline-flex"}}>
            <DatePicker
                        selectsStart
                        dateFormat="yyyy-MM-dd"
                        selected={Startdate}
                        placeholderText="YYYY-MM-DD"
                        className="cal-input"
                        onChange={changeStartDate}
                    />
                <input id="startTime" disabled="" class="time-input disable" type="text" placeholder="09:00" maxlength="5"/>
                <DatePicker
                        selectsStart
                        dateFormat="yyyy-MM-dd"
                        selected={enddate}
                        placeholderText="YYYY-MM-DD"
                        className="cal-input"
                        onChange={changeEndDate}
                    />
                <input id="endTime" disabled="" class="time-input disable" type="text" placeholder="08:59" maxlength="5"/></li>
           
          
            <li>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{padding:"0px 6px"}}><a class="btn btn-primary btn-xs search_date_btn" style={{lineHeight: '18px',width: '92pxpx',height: '30px',fontSize: "13px"}}>Serach</a></div>
              
              <select name="agent" id="agents" style={{width:'92px',height:'30px'}}><option value="100">All</option><option value="agdemo">Active</option><option value="demo22">Suspended</option><option value="mahibook">Locked</option></select></li>
        </ul>
              </div>
         
              </div>
              </div>  
            </div> */}


            <div class="row">
               <div class="col-lg-4">
                  <div class="ibox float-e-margins">
                     <div class="ibox-title">
                        <h5>PAYMENT RECEIVING FROM (Lena He)</h5>
                        <div class="ibox-tools">

                           <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                              <i class="fa fa-wrench"></i>
                           </a>
                           <ul class="dropdown-menu dropdown-user">
                              <li><a target="_blank" href="/agent/ledgers/collection_report_pdf.pdf">Export PDF</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div class="ibox-content table-responsive">
                        <table class="table" id="example_lena_hai">
                           <thead>
                              <tr>
                                 <th>Client</th>
                                 <th>Balance</th>
                              </tr>
                           </thead>
                           <tbody>

                              {PayReport.map((item, index) => {
                                 var positiveArr = item.ledgerExpo;
                                 sum_A1 = sum_A1 + parseFloat(positiveArr);
                                 let s;
                                 if (props.level == '5') {
                                    s = 'ClientDash';
                                    item.level = 6;
                                 }
                                 else {
                                    s = 'AgentDash';
                                 }
                                 return (
                                    <tr id={index}>
                                       <td>
                                          <Link to={`/${s}/${item.ledgerExpo}/${item.id}/${item.creditLimit}/${item.level}`} class="ico_account">
                                             {item.id}</Link>
                                       </td>
                                       <td>{parseFloat(item.ledgerExpo).toFixed(2)}</td>
                                    </tr>
                                 )
                              })}
                           </tbody>
                           <tfoot>
                              <tr>
                                 <th>Total</th>
                                 <th>{sum_A1.toFixed(2)}</th>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                  </div>
               </div>


               <div class="col-lg-4">
                  <div class="ibox float-e-margins">
                     <div class="ibox-title">
                        <h5>PAYMENT PAID TO (Dena He)</h5>
                        <div class="ibox-tools">

                           <a class="dropdown-toggle" data-toggle="dropdown" >
                              <i class="fa fa-wrench"></i>
                           </a>
                           <ul class="dropdown-menu dropdown-user">
                              <li><a target="_blank" href="/agent/ledgers/collection_report_pdf.pdf">Export PDF</a>
                              </li>
                           </ul>

                        </div>
                     </div>
                     <div class="ibox-content table-responsive">
                        <table class="table display" id="example_dena_hai">
                           <thead>
                              <tr>
                                 <th>Client</th>
                                 <th>Balance</th>
                              </tr>
                           </thead>
                           <tbody>
                              {ReceiveReport.map((item, index) => {
                                 var negativeArr = item.ledgerExpo;
                                 sum_B1 = sum_B1 + parseFloat(negativeArr);
                                 let s;
                                 if (props.level == '5') {
                                    s = 'ClientDash';
                                    item.level = 6;
                                 }
                                 else {
                                    s = 'AgentDash';
                                 }
                                 return (

                                    <tr key={index}>
                                       <td>
                                          <Link to={`/${s}/${item.ledgerExpo}/${item.id}/${item.creditLimit}/${item.level}`} class="ico_account">
                                             {item.id}</Link></td>
                                       <td>{parseFloat(item.ledgerExpo).toFixed(2)}</td>
                                    </tr>
                                 )
                              })}
                           </tbody>
                           <tfoot>
                              <tr>
                                 <th>Total</th>
                                 <th>{sum_B1.toFixed(2)}</th>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                  </div>
               </div>

               <div class="col-lg-4">
                  <div class="ibox float-e-margins">
                     <div class="ibox-title">
                        <h5>PAYMENT Clear (Clear Hai)</h5>
                        <div class="ibox-tools">

                           <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                              <i class="fa fa-wrench"></i>
                           </a>
                           <ul class="dropdown-menu dropdown-user">
                              <li><a target="_blank" href="/agent/ledgers/collection_report_pdf.pdf">Export PDF</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div class="ibox-content table-responsive">
                        <table class="table" id="example_lena_hai">
                           <thead>
                              <tr>
                                 <th>Client</th>
                                 <th>Balance</th>
                              </tr>
                           </thead>
                           <tbody>

                              {ClearReport.map((item, index) => {
                                 var equal = parseFloat(item.ledgerExpo).toFixed(2)

                                 sum_C1 = sum_C1 + parseFloat(equal);
                                 let s;
                                 if (props.level == '5') {
                                    s = 'ClientDash';
                                    item.level = 6;
                                 }
                                 else {
                                    s = 'AgentDash';
                                 }
                                 return (
                                    <tr id={index}>
                                       <td>
                                          <Link to={`/${s}/${item.ledgerExpo}/${item.id}/${item.creditLimit}/${item.level}`} class="ico_account">
                                             {item.id}</Link></td>
                                       <td>
                                          {parseFloat(item.ledgerExpo).toFixed(2)}</td>
                                    </tr>
                                 )
                              })}
                           </tbody>
                           <tfoot>
                              <tr>
                                 <th>Total</th>
                                 <th>{sum_C1.toFixed(2)}</th>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </React.Fragment>
   )
}
