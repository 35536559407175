import React from 'react'

export default function SubComReport() {
    return (
        <React.Fragment>

<div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>SUB COMPANY REPORT</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/agent/dashboard">Dashboard</a>
         </li>    
          <li>
            <a href="#">Admin</a>
         </li>
         
         <li>
            <a href="#"><strong>Sub Company Report</strong></a>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>
            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Summary</h5>
                </div>
                <div class="ibox-content table-responsive">

                    <table class="table table-bordered table-striped" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>All Time Company Total</th>
                                <th>
                                    5869913.94
                                </th>
                            </tr>
                            <tr>
                                <th>All Time Sub Company Total</th>
                                <th>
                                    4973206.41
                                </th>
                            </tr>
                            <tr>
                                <th>Selected Cricket, Football Company Total </th>
                                <th>
                                    3553240.39
                                </th>
                            </tr>
                            <tr>
                                <th>Selected Cricket, Football Sub Company Total </th>
                                <th>
                                    2747382.33
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Sub Company Report</h5>
                    <div class="ibox-tools">
                 
                        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                                <i class="fa fa-wrench"></i>
                            </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li><a target="_blank" href="/agent/ledgers/sub_company_report_pdf.pdf">Export PDF</a>
                            </li>
                        </ul>
                      
                    </div>
                </div>
                <div class="ibox-content table-responsive">

                    <table class="table table-bordered table-striped" id="earning-report-pdf" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>DATE/TIME</th>
                                <th>Match Id</th>
                                <th>Match Title</th>
                                <th>Company Match Earnings Share Percentage</th>
                                <th>Company Usage Charges Share Percentage</th>
                                <th>Sub Company Match Earnings</th>
                                <th>Sub Company Usage Charges Earnings</th>
                                <th>Company Match Earnings </th>
                                <th>Company Usage Charges Earnings</th>
                                <th>Sub Company Match Earnings After Company Share</th>
                                <th>Sub Company Usage Charges Earnings After Company Share</th>
                                <th>Sub Company Earnings After Company Share</th>
                                <th>Company Earnings</th>
                                <th>Company Remain Share Earnings</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>21 May 21</td>
                                <td>219255</td>
                                <td>Botanical Garden Rangers vs Dark View Explorers </td>
                                <td>51.0</td>
                                <td>100.0</td>
                                <td>215168.34
                                </td>
                                <td>2520.0
                                </td>
                                <td>109735.86
                                </td>
                                <td>2520.0
                                </td>
                                <td>105432.49
                                </td>
                                <td>0.0
                                </td>
                                <td>105432.49
                                </td>
                                <td>112255.86
                                </td>
                                <td>
                                    0
                                </td>
                            </tr>
                            <tr>
                                <td>24 Apr 21</td>
                                <td>209127</td>
                                <td>Nepal vs Netherlands</td>
                                <td>51.0</td>
                                <td>100.0</td>
                                <td>206134.81
                                </td>
                                <td>6480.0
                                </td>
                                <td>105128.75
                                </td>
                                <td>6480.0
                                </td>
                                <td>101006.06
                                </td>
                                <td>0.0
                                </td>
                                <td>101006.06
                                </td>
                                <td>111608.75
                                </td>
                                <td>
                                    0
                                </td>
                            </tr>
                        </tbody>
                    </table>
                                <div class="pagination"><span class="previous_page disabled">← Previous</span> <em class="current">1</em> <a rel="next" href="/agent/ledgers/sub_company_report?_method=get&amp;authenticity_token=Eobi5cTkiTqewM%2F20L%2B5UwNQmRI82Kqx3WmsGisnl5A%3D&amp;event_type=%5B%22cricket%22%2C%22football%22%5D&amp;page=2">2</a>
                                    <a class="next_page" rel="next" href="/agent/ledgers/sub_company_report?_method=get&amp;authenticity_token=Eobi5cTkiTqewM%2F20L%2B5UwNQmRI82Kqx3WmsGisnl5A%3D&amp;event_type=%5B%22cricket%22%2C%22football%22%5D&amp;page=2">Next →</a></div>
                    <table class="table table-bordered table-striped" id="earning-report-pdf" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>DATE/TIME</th>
                                <th>Match Id</th>
                                <th>Match Title</th>
                                <th>Company Match Earnings Share Percentage</th>
                                <th>Company Usage Charges Share Percentage</th>
                                <th>Sub Company Match Earnings</th>
                                <th>Sub Company Usage Charges Earnings</th>
                                <th>Company Match Earnings </th>
                                <th>Company Usage Charges Earnings</th>
                                <th>Sub Company Match Earnings After Company Share</th>
                                <th>Sub Company Usage Charges Earnings After Company Share</th>
                                <th>Sub Company Earnings After Company Share</th>
                                <th>Company Earnings</th>
                                <th>Company Remain Share Earnings</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th colspan="5" style={{textAlign: "center"}}>All Page Total</th>
                                <th>5606902.7</th>
                                <th>693720.0</th>
                                <th>2859520.39</th>
                                {/* <!-- Total --> */}
                                <th>693720.0</th>
                                <th>2747382.33</th>
                                <th>0.0</th>
                                <th>2747382.33</th>
                                <th>3553240.39</th>
                                <th>0.0</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
